<template>

<app-content-box :is-empty="!data.length" :is-grid="true" empty-message="No winners picked yet." class="winners" title="Latest winners" :transparent="data.length" icon="winner" :not-padded="true">

	<app-card :metrics="metrics(item)" v-for="item in data" :not-linked="true" :key="item.id" :image="item.game.bggGame.image" :title="item.name" :subtitle="'Won by ' + item.winner.name" />

</app-content-box>

</template>

<script>

export default {
	
	name: 'winners',

	props: ['data'],

	methods: {

		metrics: function(item) {

			return [
				{
					label: 'participant',
					value: item.participants
				},
				{
					label: 'play',
					value: item.plays
				}
			]

		}

	}

}

</script>

<style scoped>

.winners {
	grid-column: 1 / 5;
}

.winners >>> .box-content {
	min-height: 160px;
}

</style>
